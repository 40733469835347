import { isEmail, isMobilePhone } from "@/utils/helper";
const ERROR_MESSAGE = "This field is required.";

const businessModalForm = (values) => {
  const { phoneValue, name, email } = values;
  const errors = {};

  if (typeof name !== "string" || name.trim().length === 0) {
    errors.name = ERROR_MESSAGE;
  }

  if (typeof email !== "string" || email.trim().length === 0) {
    errors.email = ERROR_MESSAGE;
  } else if (!isEmail(email)) {
    errors.email = "Please enter valid email address.";
  }

  if (typeof phoneValue !== "string" || phoneValue.trim().length === 0) {
    errors.phoneValue = ERROR_MESSAGE;
  } else if (isMobilePhone(phoneValue)) {
    errors.phoneValue = "Please enter valid phone number";
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default businessModalForm;
