import businessModalForm from "@/validation/business-modal-form";
import axios from "axios";
import { API_URL, LINK_OF_ALL_PAGES } from "const/common";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Container from "./Container";

const BusinessModel = () => {
  const [phoneValue, setPhoneValue] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const router = useRouter();
  const { register, handleSubmit } = useForm();

  const [disabled, setDisabled] = useState(false);

  const onSubmit = (values) => {
    const { name, email } = values;
    const validationFields = {
      phoneValue,
      name,
      email,
    };
    const { isValid, errors } = businessModalForm(validationFields);

    if (isValid) {
      setDisabled(true);
      axios
        .post(`${API_URL}admin/contactusdata`, {
          ...values,
          name,
          email,
          phone: phoneValue,
          technologyName: "home",
          enquiryPage: router.asPath,
        })
        .then(() => {
          router.push("/thankyou");
        })
        .finally(() => {
          setDisabled(false);
        });
    } else {
      setErrorMessage(errors);
    }
  };

  return (
    <div className="pt-4 lg:pt-16 px-4 lg:px-16 pb-16 lg:pb-32 bg-[#f9f9f9]">
      <Container large>
        <div className="justify-between flex flex-wrap">
          <div className="w-full md:w-1/2">
            <div className={`scroll-smooth flex justify-center`}>
              <div className="pt-12 text-center w-full md:w-[93%] scroll-smooth">
                <div className="h-full">
                  <ul className="list-none p-0 m-0 scroll-smooth h-full">
                    <li className="sticky top-24 md:h-[620px] mt-0">
                      <h2 className="text-3xl md:text-4xl font-medium text-black leading-10 pl-0 md:pl-16 mb-20 text-start">
                        <span>
                          Our Flexible Business
                          <br />
                          Models
                        </span>
                      </h2>
                      <div className="bg-white sticky top-72 scroll-smooth transition-all duration-100 ease-in-out mt-20 rounded-7.5 h-[480px] lg:h-[460px] w-fit xl:w-[650px] flex justify-around items-center min-w-80">
                        <div className="flex items-baseline justify-center">
                          <div>
                            <h3 className="text-lg md:text-xl xl:text-4xl font-medium leading-10 flex justify-start m-0 pt-0 pr-8 xl:pr-0 pb-0 xl:pb-6 pl-8 xl:pl-16 text-primary-yellow">
                              <span>Staff Augmentation</span>
                            </h3>
                            <p className="py-0 px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80">
                              <span>
                                Are you tired of finding the correct person to
                                fill your existing project team or upcoming
                                projects? Let us take care of your&nbsp;
                                <Link
                                  href={
                                    LINK_OF_ALL_PAGES.services.staffAugmentation
                                  }
                                >
                                  <a>
                                    <span className="text-deep-sky-blue cursor-pointer">
                                      IT Staff Augmentation Service
                                    </span>
                                  </a>
                                </Link>
                                &nbsp;which includes recruitment, training and
                                supplying the required resources (from designer
                                to PM) to work with your team.
                              </span>
                            </p>
                            <div className="px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80 py-2">
                              <p className="mb-2">
                                <span className="text-primary-yellow mr-4 font-medium">
                                  01
                                </span>
                                <span>Share Profile(s) Requirement</span>
                              </p>
                              <p className="mb-2">
                                <span className="text-primary-yellow mr-4 font-medium">
                                  02
                                </span>
                                <span>Review Profiles</span>
                              </p>
                              <p className="mb-2">
                                <span className="text-primary-yellow mr-4 font-medium">
                                  03
                                </span>
                                <span>Meet the Candidate(s)</span>
                              </p>
                              <p className="mb-2">
                                <span className="text-primary-yellow mr-4 font-medium">
                                  04
                                </span>
                                <span>Start the Contract</span>
                              </p>
                            </div>
                            <div>
                              <p className="text-xs md:text-sm lg:text-base font-medium py-0 pl-8 xl:pl-16 pr-8 lg:pr-0 text-start">
                                <span>
                                  Connect now to&nbsp;
                                  <span className="text-primary-yellow">
                                    fill in your missing team members!
                                  </span>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bg-white sticky top-72 scroll-smooth transition-all duration-100 ease-in-out mt-40 rounded-7.5 h-[480px] lg:h-[460px] w-fit xl:w-[650px] flex justify-around items-center min-w-80">
                      <div className="flex items-baseline justify-center">
                        <div>
                          <h3 className="text-lg md:text-xl xl:text-4xl font-medium leading-10 flex justify-start m-0 pt-0 pr-8 xl:pr-0 pb-0 xl:pb-6 pl-8 xl:pl-16 text-primary-yellow">
                            <span> Dedicated Team Hiring</span>
                          </h3>
                          <p className="py-0 px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80">
                            <span>
                              Do you want to build your on-demand product team
                              for agile development? Let us build your fully
                              functional dedicated team by supplying FULL-TIME
                              resources consisting of designers, developers, QC,
                              project managers & more.
                            </span>
                          </p>
                          <div className="px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80 py-2">
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                01
                              </span>
                              <span>Define Product Team</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                02
                              </span>
                              <span>Agree on Commercials</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                03
                              </span>
                              <span>Start the Contract</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                04
                              </span>
                              <span>Define Project Sprints</span>
                            </p>
                          </div>
                          <div>
                            <p className="text-xs md:text-sm lg:text-base font-medium py-0 pl-8 xl:pl-16 pr-8 lg:pr-0 text-start">
                              <span>
                                Check out our&nbsp;
                                <span className="text-primary-yellow">
                                  Free trial
                                </span>
                                &nbsp;options now!
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bg-white sticky top-72 scroll-smooth transition-all duration-100 ease-in-out mt-40 rounded-7.5 h-[480px] lg:h-[460px] w-fit xl:w-[650px] flex justify-around items-center min-w-80">
                      <div className="flex items-baseline justify-center">
                        <div>
                          <h3 className="text-lg md:text-xl xl:text-4xl font-medium leading-10 flex justify-start m-0 pt-0 pr-8 xl:pr-0 pb-0 xl:pb-6 pl-8 xl:pl-16 text-primary-yellow">
                            <span>Project Basis</span>
                          </h3>
                          <p className="py-0 px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80">
                            <span>
                              Do you want to work on a mid-size project with a
                              clear set of requirements and/ or scope of work?
                              With this business model, the team will document
                              your requirements with defined technical
                              solutions, time frames, and budget.
                            </span>
                          </p>
                          <div className="px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80 py-2">
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                01
                              </span>
                              <span>Share Project Requirement</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                02
                              </span>
                              <span>Define Scope of work</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                03
                              </span>
                              <span>Define Project Price & Plan</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                04
                              </span>
                              <span>Start the Contract</span>
                            </p>
                          </div>
                          <div>
                            <p className="text-xs md:text-sm lg:text-base font-medium py-0 pl-8 xl:pl-16 pr-8 lg:pr-0 text-start">
                              <span>
                                Connect with us quickly to&nbsp;
                                <span className="text-primary-yellow">
                                  start your project!
                                </span>
                                &nbsp;
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bg-white sticky top-72 scroll-smooth transition-all duration-100 ease-in-out mt-40 rounded-7.5 h-[480px] xl:h-[460px] w-fit xl:w-[650px] flex justify-around items-center min-w-80">
                      <div className="flex items-baseline justify-center">
                        <div>
                          <h3 className="text-lg md:text-xl xl:text-4xl font-medium leading-10 flex justify-start m-0 pt-0 pr-8 xl:pr-0 pb-0 xl:pb-6 pl-8 xl:pl-16 text-primary-yellow">
                            <span>Technology Partner</span>
                          </h3>
                          <p className="py-0 px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80">
                            <span>
                              Do you want to be our B2B partner? We know that it
                              is not always possible to have the required
                              technology experts, and that is where our&nbsp;
                              <Link href="https://ultroneous.com/">
                                <a>
                                  <span className="text-deep-sky-blue">
                                    software development services
                                  </span>
                                </a>
                              </Link>
                              &nbsp;come into play. While we focus on filling
                              your shortage of resources, rest assured that your
                              ideas/ clients are secured with us.
                            </span>
                          </p>
                          <div className="px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80 py-2">
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                01
                              </span>
                              <span>Discuss Ongoing requirements</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                02
                              </span>
                              <span>Share Resource Profiles</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                03
                              </span>
                              <span>Meet the Candidate(s)</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                04
                              </span>
                              <span>Start the Contract</span>
                            </p>
                          </div>
                          <div>
                            <p className="text-xs md:text-sm lg:text-base font-medium py-0 pl-8 xl:pl-16 pr-8 lg:pr-0 text-start">
                              <span>
                                Let’s sign the MSA and&nbsp;
                                <span className="text-primary-yellow">
                                  exchange work!
                                </span>
                                &nbsp;
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="bg-white sticky top-72 scroll-smooth transition-all duration-100 ease-in-out mt-40 rounded-7.5 h-[480px] lg:h-[460px] w-fit xl:w-[650px] flex justify-around items-center min-w-80">
                      <div className="flex items-baseline justify-center">
                        <div>
                          <h3 className="text-lg md:text-xl xl:text-4xl font-medium leading-10 flex justify-start m-0 pt-0 pr-8 xl:pr-0 pb-0 xl:pb-6 pl-8 xl:pl-16 text-primary-yellow">
                            <span>Maintenance & Support</span>
                          </h3>
                          <p className="py-0 px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80">
                            <span>
                              Are you stuck in finding a reliable&nbsp;
                              <Link
                                href={
                                  LINK_OF_ALL_PAGES.services
                                    .customSoftwareDevelopment
                                }
                              >
                                <a>
                                  <span className="text-deep-sky-blue">
                                    custom software development company
                                  </span>
                                </a>
                              </Link>
                              &nbsp; to take care of your existing product, or
                              who can clear the mess your previous agency
                              created? We can take care of your product on a
                              daily basis or upgrade your product with a set of
                              new features development.
                            </span>
                          </p>
                          <div className="px-8 xl:px-16 text-sm md:text-sm xl:text-base font-extralight leading-5 text-justify mb-6 opacity-80 py-2">
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                01
                              </span>
                              <span>Understand your product</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                02
                              </span>
                              <span>Setup and Review codebase</span>
                            </p>
                            <p className="mb-2">
                              <span className="text-primary-yellow mr-4 font-medium">
                                03
                              </span>
                              <span>Define Support Type</span>
                            </p>
                            <p>
                              <span className="text-primary-yellow mr-4 font-medium">
                                04
                              </span>
                              <span>Start the Contract</span>
                            </p>
                          </div>
                          <div>
                            <p className="text-xs md:text-sm lg:text-base font-medium py-0 pl-8 xl:pl-16 pr-8 lg:pr-0 text-start">
                              <span>
                                We are ready to&nbsp;
                                <span className="text-primary-yellow">
                                  take care of your project!
                                </span>
                                &nbsp;
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-24 flex justify-center md:block w-full md:w-1/2 xl:w-1/3">
            <div className="p-4 sticky flex items-center bg-white rounded-[20px] w-[350px] lg:w-[420px] h-[460px] my-0 mx-auto top-64">
              <form
                className="flex flex-col w-full my-8 px-0"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input
                  {...register("name")}
                  autoComplete={"off"}
                  className="mx-4 mt-4 bg-white p-4 border border-solid border-black/10 rounded-lg focus-visible:outline-none"
                  id="hireDeveloper"
                  placeholder="Full Name*"
                />
                {errorMessage.name && (
                  <label className="ml-4 text-red-700">
                    {errorMessage.name}
                  </label>
                )}
                <div className="businessData">
                  <div className="mt-4">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      value={phoneValue}
                      onChange={(value) => setPhoneValue(value)}
                    />
                  </div>
                  {errorMessage.phoneValue && (
                    <label className="ml-4 text-red-700">
                      {errorMessage.phoneValue}
                    </label>
                  )}
                </div>
                <input
                  type={"text"}
                  {...register("email")}
                  autoComplete={"off"}
                  className="mx-4 mt-4 bg-white p-4 border border-solid border-black/10 rounded-lg focus-visible:outline-none"
                  placeholder="Email ID*"
                />
                {errorMessage.email && (
                  <label className="ml-4 text-red-700">
                    {errorMessage.email}
                  </label>
                )}
                <input
                  className="m-4 rounded-lg border border-solid border-primary-yellow text-xs md:text-lg text-primary-yellow hover:text-white font-medium outline-none bg-transparent hover:bg-primary-yellow transition-all duration-500 ease-in-out py-4 inline-block max-w-min px-14 cursor-pointer"
                  type="submit"
                  disabled={disabled}
                  value="Reach me quickly"
                ></input>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BusinessModel;
